<template>
    <div class="input-group color-picker" ref="colorpicker" style="width: 100%">
        <input
            type="text"
            :id="id"
            class="ownFormControl"
            v-model="colorValue"
            @focus="showPicker()"
            @input="updateFromInput"
        />
        <span class="input-group-addon color-picker-container" style="background-color: grey">
            <div
                style="width: 35px;
                       height: 35px;
                       display: inline-block;
                       position: absolute;
                       right: 0px;"
            >
                <span
                    class="current-color"
                    :style="'background-color: ' + colorValue"
                    style="
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        background-color: #000;
                        cursor: pointer;
                        position: absolute;
                        top: 6px;
                        right: 6px;
                        border-radius: 3px
                    "
                    @click="togglePicker()">
                </span>
            </div>
            <chrome-picker
                :value="colors"
                @input="updateFromPicker"
                v-if="displayPicker"
                class="picker"
            />
        </span>
    </div>
</template>

<script>
import { Chrome } from "vue-color";

export default {
  name: "colorpicker",
  components: {
    "chrome-picker": Chrome,
  },

  props: ["color", "position", "id"],
  data() {
    return {
      colors: {
        hex: this.color,
      },
      colorValue: "",
      displayPicker: false,
    };
  },
  mounted() {
    this.setColor(this.color);
  },
  methods: {
    updateCSSVariable: function (name, value) {
      if (this.$el) {
        this.$el.style.setProperty(name, value);
      }
    },
    
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == "#") {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == "rgba") {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(","),
          hex =
            "#" +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          "rgba(" +
          color.rgba.r +
          ", " +
          color.rgba.g +
          ", " +
          color.rgba.b +
          ", " +
          color.rgba.a +
          ")";
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit("input", val);
      }
    },
  },
};
</script>
<style scoped>
.vc-chrome {
    position: absolute;
    right: 0;
    z-index: 9;
}
.current-color {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #000;
    cursor: pointer;
}
.picker {
    font-family: inherit;
}
input[type=text] {
    font-family: inherit;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 400;
    border-radius: 4px;
    border: 2px solid #e1e3eb;
    transition: border .15s ease;
    line-height: 30px;
    padding: 0 10px;
    cursor: pointer;
}
input[type=text]:focus {
    outline: none !important;
    border-color: #0060df;
}
</style>