<template>
    <div>
        <Header v-if="showPOCForm" overlapping-content="true">
            <template v-slot:header-title>
                POC Trial
            </template>
            <template v-slot:header-strapline>
                A free of any obligation opportunity to explore our application. We have designed our UI to need no pre-requisite training for a user to be able to build and manage workloads across mutliple clouds both public and private.
                <br><br>
                During your trial you will be connected directly to our infrastructure sandbox, so completely autonomous from any production infrastructure.
            </template>
        </Header>
        <!-- POC Trial Form -->
        <section class="poc-trial-form-section" v-if="showPOCForm">
            <div class="form-wrapper">
                <form @submit.prevent="ValidateAllInputs"
                      class="poc-trial-form"
                      ref="pocTrialForm"
                      name="pocTrialForm">
                    <!-- Form Submission Errors -->
                    <div class="error-messages-wrapper" v-if="submissionErrors">
                        <div class="error-heading flex flex-row align-center">
                            <div class="error-heading-icon">
                                <i class="bi bi-exclamation-circle-fill"></i>
                            </div>
                            <div class="error-heading-title">Errors</div>
                            </div>
                        <div class="error-body">
                            There were errors detected in your submission. Please fix these and try again.
                        </div>
                    </div>
                    <!-- Email Code Authentication Errors -->
                    <div class="error-messages-wrapper" v-if="emailVerificationCodeErrorMessage != ''">
                        <div class="error-body">
                            {{emailVerificationCodeErrorMessage}}
                        </div>
                    </div>
                    <!-- Mobile Code Authentication Errors -->
                    <div class="error-messages-wrapper" v-if="mobileVerificationCodeErrorMessage != ''">
                        <div class="error-body">
                            {{mobileVerificationCodeErrorMessage}}
                        </div>
                    </div>
                    <!-- Primary Contact -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row align-center">
                            <i class="bi bi-person"></i>
                            <div class="fields-heading">Primary Contact</div>
                        </div>
                        <div class="primary-contact">
                            <div class="multiple-fields">
                                <div class="field">
                                    <label for="primaryContactName">
                                        Full Name 
                                        <span class="required">*</span>
                                    </label>
                                    <input type="text"
                                           name="primaryContactName"
                                           id="primaryContactName"
                                           v-model="primaryContact.fullName"
                                           :class="{ 'form-input-error': errorMessages.primaryContact.fullName != '' }">
                                    <div class="error-message" v-if="errorMessages.primaryContact.fullName">
                                        {{errorMessages.primaryContact.fullName}}
                                    </div>
                                </div>
                                <div class="field flex-width">
                                    <label for="primaryContactEmail">
                                        Email 
                                        <span class="required">*</span>
                                    </label>
                                    <input type="text"
                                           name="primaryContactEmail"
                                           id="primaryContactEmail"
                                           v-model="primaryContact.email"
                                           :class="{ 'form-input-error': errorMessages.primaryContact.email != '' }">
                                    <div class="error-message" v-if="errorMessages.primaryContact.email">
                                        {{errorMessages.primaryContact.email}}
                                    </div>
                                </div>
                                <div class="field">
                                    <label for="primaryContactMobile">
                                        Mobile 
                                        <span class="required">*</span>
                                    </label>
                                    <div class="field flex-row" style="margin: 0; margin-bottom: 10px;">
                                        <AreaCodeDropdown :selectCountry="selectCountry"
                                                          :dropdownValue="dropdownValue"
                                                          ref="primaryContactAreaCodeDropdown"
                                                          :user="false"
                                                          :invalid="errorMessages.primaryContact.areaCode">
                                                          <!-- {{dropdownValue}} -->
                                        </AreaCodeDropdown>
                                        <input type="number"
                                               style="margin-left: 5px !important; margin-right: 0 !important;"
                                               name="primaryContactMobile"
                                               id="primaryContactMobile"
                                               v-model="primaryContact.mobile"
                                               :class="{ 'form-input-error': errorMessages.primaryContact.mobile != '' }"
                                               v-on:keypress="NumbersOnly"
                                               @wheel="$event.target.blur()">
                                    </div>
                                    <div class="error-message" v-if="errorMessages.primaryContact.mobile">
                                        {{errorMessages.primaryContact.mobile}}
                                    </div>
                                    <div class="error-message" v-if="errorMessages.primaryContact.areaCode">
                                        {{errorMessages.primaryContact.areaCode}}
                                    </div>
                                </div>
                                <div class="field">
                                    <label for="primaryContactSubmissionDate">Date</label>
                                    <input type="text"
                                           readonly
                                           disabled
                                           class="disabled"
                                           @focus="showContactDatepicker = true" 
                                           @blur="showContactDatepicker = false"
                                           :value="primaryContactDateString"
                                           id="primaryContactSubmissionDate"
                                           name="primaryContactSubmissionDate"
                                           v-model="primaryContactDateString">
                                    <div class="contact-datepicker">
                                        <datepicker :inline="true"
                                                    v-show="showContactDatepicker"
                                                    ref="contactDatePicker"
                                                    v-model="primaryContactDate">
                                        </datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Company -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row align-center">                            
                            <i class="bi bi-briefcase"></i>
                            <div class="fields-heading">Company</div>
                        </div>
                        <div class="multiple-fields">
                            <div class="field" id="company-name-field">
                                <label for="companyName">
                                    Company Name 
                                    <span class="required">*</span>
                                </label>
                                <input  type="text"
                                        name="companyName"
                                        id="companyName"
                                        v-model="companyDetails.name"
                                        :class="{ 'form-input-error': errorMessages.companyDetails.name != '' }">
                                <div class="error-message" v-if="errorMessages.companyDetails.name">
                                    {{errorMessages.companyDetails.name}}
                                </div>
                            </div>
                            <div class="field flex-width">
                                <label for="companyAddressLineOne">
                                    Address Line 1 
                                    <span class="required">*</span>
                                </label>
                                <input  type="text"
                                        name="companyAddressLineOne"
                                        id="companyAddressLineOne"
                                        v-model="companyDetails.address.line1"
                                        :class="{ 'form-input-error': errorMessages.companyDetails.line1 != '' }">
                                <div class="error-message" v-if="errorMessages.companyDetails.line1">
                                    {{errorMessages.companyDetails.line1}}
                                </div>
                            </div>
                            <div class="field">
                                <label for="companyAddressLineTwo">Address Line 2</label>
                                <input  type="text"
                                        name="companyAddressLineTwo"
                                        id="companyAddressLineTwo"
                                        v-model="companyDetails.address.line2">
                            </div>
                            <div class="field">
                                <label for="companyAddressLineThree">Address Line 3</label>
                                <input  type="text"
                                        name="companyAddressLineThree"
                                        id="companyAddressLineThree"
                                        v-model="companyDetails.address.line3">
                            </div>
                            <div class="field" id="zipcode-field">
                                <label for="companyZipPostalCode">
                                    Postal / Zip Code 
                                    <span class="required">*</span>
                                </label>
                                <input  type="text"
                                        name="companyZipPostalCode"
                                        id="companyZipPostalCode"
                                        v-model="companyDetails.zipCode"
                                        :class="{ 'form-input-error': errorMessages.companyDetails.zipCode != '' }">
                                <div class="error-message" v-if="errorMessages.companyDetails.zipCode">
                                    {{errorMessages.companyDetails.zipCode}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Setup -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row align-center">                            
                            <i class="bi bi-gear"></i>
                            <div class="fields-heading">Setup</div>
                        </div>
                        <div class="multiple-fields">
                            <div class="field flex-width">
                                <label for="subdomain">
                                    Subdomain 
                                    <span class="required">*</span>
                                </label>
                                <input type="text"
                                       name="subdomain"
                                       id="subdomain"
                                       v-model="setupDetails.subdomain"
                                       :class="{ 'form-input-error': errorMessages.setupDetails.subdomain != '' }">
                                <div class="error-message" v-if="errorMessages.setupDetails.subdomain">
                                    {{errorMessages.setupDetails.subdomain}}
                                </div>
                            </div>
                            <div class="field flex-width">
                                <label for="domain">Domain</label>
                                <input type="text"
                                       readonly
                                       disabled
                                       name="domain"
                                       id="domain"
                                       value="illapa.cloud"
                                       class="disabled">
                            </div>
                            <div class="field flex-width">
                                <label for="service-name">
                                    Service Name 
                                    <span class="required">*</span>
                                </label>
                                <input type="text"
                                       name="serviceName"
                                       id="service-name"
                                       v-model="setupDetails.serviceName"
                                       :class="{ 'form-input-error': errorMessages.setupDetails.serviceName != '' }">
                                <div class="error-message" v-if="errorMessages.setupDetails.serviceName">
                                    {{errorMessages.setupDetails.serviceName}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Styling -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row  align-center">
                            <i class="bi bi-palette"></i>                            
                            <div class="fields-heading">Styling</div>
                        </div>
                        <div class="fields-sub-heading">Corporate Logos</div>
                        <div class="multiple-fields">
                            <div class="field file-upload">
                                <div class="flex-icon">
                                    <i class="bi bi-image text-dark-terniary"></i>
                                    <label for="login-page-image">Logo: Login Page</label>
                                </div>
                                <div class="field-tooltip">Recommended image width: 256px</div>
                                <input type="file" id="login-page-image" accept="image/*">
                            </div>
                            <div class="field file-upload">
                                <div class="flex-icon">
                                    <i class="bi bi-image text-dark-terniary"></i>
                                    <label for="sidebar-image">Logo: Sidebar Expanded</label>
                                </div>
                                <div class="field-tooltip">Recommended image width: 256px</div>
                                <input type="file" id="sidebar-image" accept="image/*">
                            </div>
                            <div class="field file-upload">
                                <div class="flex-icon">
                                    <i class="bi bi-image text-dark-terniary"></i>                                    
                                    <label for="large-favicon-image">Logo: Sidebar Collapsed</label>
                                </div>
                                <div class="field-tooltip">Recommended image width: 256px</div>
                                <input type="file" id="large-favicon-image" accept="image/*">
                            </div>
                            <div class="field file-upload">
                                <div class="flex-icon">
                                    <i class="bi bi-image text-dark-terniary"></i>
                                    <label for="footer-image">Logo: Footer</label>
                                </div>
                                <div class="field-tooltip">Recommended image width: 256px</div>
                                <input type="file" id="footer-image" accept="image/*">
                            </div>
                            <div class="field file-upload">
                                <div class="flex-icon">
                                    <i class="bi bi-image text-dark-terniary"></i>
                                    <label for="small-favicon-image">Logo: Favicon</label>
                                </div>
                                <div class="field-tooltip">Recommended image size: 192x192px</div>
                                <input type="file" id="small-favicon-image" accept="image/*">
                            </div>
                        </div>
                    </div>
                    <!-- Colour Palette -->
                    <div class="form-section">
                        <div class="fields-sub-heading">Colour Palette</div>
                        <div class="multiple-fields">
                            <div class="field">
                                <label for="primary-colour">Primary Colour</label>
                                <colorpicker :position="'top'"
                                             :color="primaryColour.hex"
                                             v-model="primaryColour.hex"
                                             id="primary-colour"/>
                            </div>
                            <div class="field">
                                <label for="secondary-colour">Secondary Colour</label>
                                <colorpicker :position="'top'"
                                             :color="secondaryColour.hex"
                                             v-model="secondaryColour.hex"
                                             id="secondary-colour"/>
                            </div>
                        </div>
                    </div>
                    <!-- Users -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row align-center">                            
                            <i class="bi bi-people"></i>
                            <div class="fields-heading">Users</div>
                        </div>
                        <div class="fields-sub-heading">Add Users</div>
                        <div class="field-tooltip">Add users of differing levels of privilege</div>
                        <div class="users">
                            <!-- Primary Contact: Auto-Filled -->
                            <div class="multiple-fields primary-contact-auto-fill">
                                <div class="heading">
                                    <i class="bi bi-lock-fill"></i>
                                    Primary Contact
                                </div>
                                <div class="field">
                                    <label for="prime-admin-name">Full Name</label>
                                    <input  type="text"
                                            id="prime-admin-name"
                                            v-model="primaryContact.fullName"
                                            disabled="disabled">
                                </div>
                                <div class="field flex-width">
                                    <label for="prime-admin-email">Email</label>
                                    <input type="text"
                                           v-model="primaryContact.email"
                                           disabled="disabled"
                                           id="prime-admin-email">
                                </div>
                                <div class="field">
                                    <label for="prime-admin-mobile">Mobile</label>
                                    <div class="field flex-row" style="margin: 0; margin-bottom: 10px;">
                                        <AreaCodeDropdown :selectCountry="selectCountry"
                                                          :dropdownValue="dropdownValue"
                                                          ref="primaryContactAreaCodeDropdown"
                                                          :user="false"
                                                          :disabled="true">
                                                          {{dropdownValue}}
                                        </AreaCodeDropdown>
                                        <input type="number"
                                               style="margin-left: 5px !important; margin-right: 0 !important; flex: 1 !important;"
                                               v-model="primaryContact.mobile"
                                               disabled="disabled"
                                               id="prime-admin-mobile">
                                    </div>
                                </div>
                                <div class="field">
                                    <label for="user-role">User Role</label>
                                    <v-select class="user-role"
                                              :clearable="false"
                                              :disabled="true"
                                              value="Prime Admin">
                                    </v-select>
                                </div>
                                <div class="field delete-user-locked">
                                    <div id="delete-user" class="delete-user-button disabled">
                                        <i class="bi bi-lock-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <!-- Users: For Loop -->
                            <div class="multiple-fields" v-for="(user, index) in users" :key="user">
                                <div class="field">
                                    <label :for="`user-name-${index}`">
                                        Full Name 
                                        <span class="required">*</span>
                                    </label>
                                    <input type="text"
                                           :id="`user-name-${index}`"
                                           v-model="user.name"
                                           :class="{'form-input-error': errorMessages.users[index].fullName != '' }"
                                           v-on:input="errorMessages.users[index].fullName = '',
                                                       ValidateGeneric(user.name, 'users', 'fullName', true, index),
                                                       ChangeInputMade(index)">
                                    <div class="error-message" v-if="errorMessages.users[index].fullName">
                                        {{errorMessages.users[index].fullName}}
                                    </div>
                                </div>
                                <div class="field flex-width">
                                    <label :for="`user-email-${index}`">
                                        Email 
                                        <span class="required">*</span>
                                    </label>
                                    <input type="text"
                                           :id="`user-email-${index}`"
                                           v-model="user.email"
                                           :class="{'form-input-error': errorMessages.users[index].email != '' }"
                                           v-on:input="newTotalEmailValidation(),
                                                       ValidateGeneric(user.email, 'users', 'email', true, index),
                                                       ChangeInputMade(index)">
                                    <div class="error-message" v-if="errorMessages.users[index].email">
                                        {{errorMessages.users[index].email}}
                                    </div>
                                </div>
                                <div class="field">
                                    <label :for="`user-mobile-${index}`">
                                        Mobile 
                                        <span class="required">*</span>
                                    </label>
                                    <div class="field flex-row" style="margin: 0; margin-bottom: 10px;">
                                        <AreaCodeDropdown :selectCountry="selectCountry"
                                                          :dropdownValue="user.dropdownValue"
                                                          :ref="'userAreaCodeDropdown-' + index"
                                                          :index="index"
                                                          :user="true"
                                                          :invalid="errorMessages.users[index].areaCode">
                                                          <!-- {{user.dropdownValue}} -->
                                        </AreaCodeDropdown>
                                        <input type="number"
                                               :id="`user-mobile-${index}`"
                                               style="margin-left: 5px !important; margin-right: 0 !important; flex: 1 !important;"
                                               v-model="user.mobile"
                                               :class="{'form-input-error': errorMessages.users[index].mobile != '' }"
                                               v-on:input="errorMessages.users[index].mobile = '',
                                                           newTotalMobileValidation(),
                                                           ValidateGeneric(user.mobile, 'users', 'mobile', true, index),
                                                           ChangeInputMade(index)"
                                               v-on:keypress="NumbersOnly"
                                               @wheel="$event.target.blur()">
                                    </div>
                                    <div class="error-message" v-if="errorMessages.users[index].mobile">
                                        {{errorMessages.users[index].mobile}}
                                    </div>
                                    <div class="error-message" v-if="errorMessages.users[index].areaCode">
                                        {{errorMessages.users[index].areaCode}}
                                    </div>
                                </div>
                                <div class="field">
                                    <label for="user-role">
                                        User Role 
                                        <span class="required">*</span>
                                    </label>
                                    <v-select class="user-role"
                                              :options="['Prime Admin', 'Admin', 'Standard User', 'Partial User']"
                                              :clearable="false"
                                              :placeholder="'User Role'"
                                              v-model="user.role"
                                              v-on:input="ValidateGeneric(user.role, 'users', 'role', true, index),
                                                          ValidateUserRole(user.role, 'role', true, index),
                                                          ChangeInputMade(index)">
                                    </v-select>
                                    <div class="error-message" style="margin-top: 10px;" v-if="errorMessages.users[index].role">
                                        {{errorMessages.users[index].role}}
                                    </div>
                                </div>
                                <div class="field">
                                    <div id="delete-user" class="delete-user-button" @click="DeleteUser(index)">
                                        <i class="bi bi-trash"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="add-user" @click="AddUser()">Add a User</div>
                        </div>
                    </div>
                     <!-- Clouds -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row align-center">                            
                            <i class="bi bi-cloud"></i>
                            <div class="fields-heading">Clouds</div>
                        </div>
                        <div class="multiple-fields clouds">
                            <!-- VMware -->
                            <div class="field cloud">
                                <div class="cloud-logo">
                                    <div class="active-state-icon default">
                                        <i class="bi"
                                           :class="[clouds[0].status ? 'bi-check-circle-fill' : 'bi-circle']"></i>
                                    </div>
                                    <img src="/images/clouds/vmware.webp" alt="VMware vCenter">
                                </div>
                                <div class="cloud-name">VMWare vCenter</div>
                            </div>
                            <!-- Nutanix -->
                            <div class="field cloud">
                                <div class="cloud-logo">
                                    <div class="active-state-icon default">
                                        <i class="bi"
                                           :class="[clouds[1].status ? 'bi-check-circle-fill' : 'bi-circle']"></i>
                                    </div>
                                    <img src="/images/clouds/nutanix.webp" alt="Nutanix AHV">
                                </div>
                                <div class="cloud-name">Nutanix AHV</div>
                            </div>
                            <!-- AWS -->
                            <div class="field cloud" @click="SelectedOptionalCloudEnvironment(2)">
                                <div class="cloud-logo">
                                    <div class="active-state-icon" :class="{'checked': clouds[2].status}">
                                        <i class="bi"
                                           :class="[clouds[2].status ? 'bi-check-circle-fill' : 'bi-circle']">
                                        </i>
                                        <i class="bi bi-exclamation-circle-fill warning"></i>
                                    </div>
                                    <img src="/images/clouds/aws.webp" alt="AWS" id="cloud-aws-logo">
                                </div>
                                <div class="cloud-name">AWS (Optional)</div>
                            </div>
                            <!-- Azure -->
                            <div class="field cloud" @click="SelectedOptionalCloudEnvironment(3)">
                                <div class="cloud-logo">
                                    <div class="active-state-icon" :class="{'checked': clouds[3].status}">
                                        <i class="bi"
                                           :class="[clouds[3].status ? 'bi-check-circle-fill' : 'bi-circle']">
                                        </i>
                                        <i class="bi bi-exclamation-circle-fill warning"></i>
                                    </div>
                                    <img src="/images/clouds/azure.webp" alt="Azure">
                                </div>
                                <div class="cloud-name">Azure (Optional)</div>
                            </div>
                        </div>
                        <div class="info-message-with-icon banner flex flex-row align-center" v-if="pocOptionalCloudEnvironmentSelected">
                            <div class="info-icon">
                                <i class="bi bi-exclamation-circle-fill"></i>
                            </div>
                            <div class="info-message">
                                Anything provisioned in this environment will be charged to the client.
                            </div>
                        </div>
                    </div>
                    <!-- POC Timeline -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row align-center">
                            <i class="bi bi-calendar-event"></i>
                            <div class="fields-heading">POC Timeline</div>
                        </div>
                        <div class="multiple-fields">
                            <div class="multiple-fields">
                                <div class="field" style="margin-bottom: 0;">
                                    <label for="start-date">Start Date</label>
                                    <input type="text"
                                           id="start-date"
                                           style="cursor: pointer; caret-color: transparent;"
                                           @focus="showStartDatepicker = true"
                                           @blur="showStartDatepicker = false"
                                           :value="startDateString">
                                    <div class="poc-timeline-datepicker">
                                        <datepicker :inline="true"
                                                    v-show="showStartDatepicker"
                                                    ref="startDatePicker"
                                                    v-model="startDate"
                                                    :disabled-dates="pocTimelineStartDateState.disabledDates">
                                        </datepicker>
                                    </div>
                                </div>
                                <div class="field" style="margin-bottom: 0;">
                                    <label for="end-date">End Date</label>
                                    <input type="text"
                                           id="end-date"
                                           style="cursor:pointer; caret-color: transparent;"
                                           @focus="showEndDatepicker = true"
                                           @blur="showEndDatepicker = false"
                                           :value="endDateString">
                                    <div class="poc-timeline-datepicker">
                                        <datepicker :inline="true"
                                                    v-show="showEndDatepicker"
                                                    ref="endDatePicker"
                                                    v-model="endDate"
                                                    :disabled-dates="pocTimelineEndDateState.disabledDates">
                                        </datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="info-message-with-icon banner flex flex-row align-center" v-if="pocTimelineEndDateExceedingTrialLength">
                                <div class="info-icon">
                                    <i class="bi bi-exclamation-circle-fill"></i>
                                </div>
                                <div class="info-message">
                                    Your chosen end date would exceed the free of charge period (14 days). Any extension to the POC beyond this period will be at a cost of $200.00 per month.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Trial Extensions -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row align-center">
                            <i class="bi bi-calendar-plus"></i>
                            <div class="fields-heading">Trial Extension</div>
                        </div>
                        <div class="field">
                            <p class="text-dark-secondary" style="line-height: 1.5rem">
                                The user interface has been designed to be intuitive and requires no prerequisite training, and we believe that our 14 day POC Trial will provide sufficient time to both appreciate the power of the application, and an opportunity to understand how to navigate the user interface. If more time is required then the trial can be extended by a further 14 days at a cost of $200.
                            </p>
                        </div>
                    </div>
                    <!-- Trial Objectives -->
                    <div class="form-section">
                        <div class="fields-heading-wrapper flex flex-row align-center">
                            <i class="bi bi-journal-check"></i>
                            <div class="fields-heading">Trial Objectives</div>
                        </div>
                        <div class="field">
                            <textarea   name=""
                                        id="trial-objectives"
                                        cols="30"
                                        rows="10"
                                        maxlength="2000"
                                        placeholder="What would you like to achieve from your time with our platform?"
                                        v-model="setupDetails.objectives"
                                        :class="{ 'form-input-error': errorMessages.setupDetails.objectives != '' }">
                            </textarea>
                            <div class="error-message" v-if="errorMessages.setupDetails.objectives">
                                {{errorMessages.setupDetails.objectives}}
                            </div>
                        </div>
                    </div>
                    <!-- Confidentiality Agreement -->
                    <div class="form-section" style="margin-bottom: 50px;">
                        <div class="fields-heading-wrapper flex flex-row align-center">
                            <i class="bi bi-pencil-square"></i>
                            <div class="fields-heading">Confidentiality Agreement</div>
                        </div>
                        <div class="field">
                            <p class="text-dark-secondary" style="line-height: 1.5rem">
                                Please agree to our Confidentiality Agreement
                                <br><br>
                                By submitting below, the Submitter represents that they have read, understood and agree that this Trial Agreement is governed by the terms located at opsmgr.illapa.cloud and thus provides consent for illapa to contact the above 'Primary Contact' in affiliation with this trial process only.
                            </p>
                        </div>
                        <div class="field flex-row">
                            <input  type="checkbox"
                                    id="confidentiality-checkbox"
                                    v-model="agreedToTerms">
                            <label for="confidentiality-checkbox" @click.prevent="DisableCheckboxLabelClick($event)">I have read and accept the terms within your <span class="confidentiality-link" @click="OpenPDF()">Confidentiality Terms</span>.</label>
                        </div>
                        <div class="field flex-row">
                            <input  type="checkbox"
                                    id="privacy-checkbox"
                                    v-model="agreedToPrivacyPolicy">
                            <label for="privacy-checkbox" @click.prevent="DisableCheckboxLabelClick($event)">I have read and accept the terms within your <span class="confidentiality-link" @click="OpenLinkInNewTab('legal', 'privacy-policy')">Privacy Policy</span>.</label>
                        </div>
                        <div class="error-message" v-if="confidentialityAgreementErrorMessage">
                            {{confidentialityAgreementErrorMessage}}
                        </div>
                        <div class="error-message" v-if="privacyPolicyAgreementErrorMessage">
                            {{privacyPolicyAgreementErrorMessage}}
                        </div>
                    </div>
                    <!-- Submit Button -->
                    <button class="form-submit-button">Submit</button>
                    <div class="loading-animation-wrapper" v-if="verifyingPOCForm">
                        <div class="loading-message">Submitting...</div>
                        <SpinningDotsLoader :color="'#00bcc5'" :scale="0.5"></SpinningDotsLoader>
                    </div>
                </form>
            </div>
        </section>
        <!-- Email Verification Form -->
        <section class="poc-trial-form-email-verification-section" v-if="showEmailVerificationForm">
            <div class="form-wrapper">
                <form class="poc-trial-form-email" ref="emailCodeForm">
                    <div class="form-section m-0">
                        <div class="back-button-wrapper flex flex-row align-center margin-bottom-regular"
                             @click="showEmailVerificationForm = false; showPOCForm = true;">
                            <div class="back-button-icon"><i class="bi bi-arrow-left-short"></i></div>
                            <div class="back-button-text">Go Back</div>
                        </div>
                        <div class="fields-heading-wrapper flex flex-row align-center">
                            <i class="bi bi-envelope-open"></i>
                            <div class="fields-heading">Email Verification</div>
                        </div>
                        <div class="tooltip">
                            We have sent a six digit code to the Primary Contact's email address <b>{{primaryContact.email}}</b>
                            <br><br>
                            Enter the code here to verify the email address.
                        </div>
                        <div class="email-code">
                            <div class="multiple-fields space-between">
                                <div class="field" style="flex: 1;">
                                    <input type="text" maxlength="6" v-model="emailVerificationCode" style="max-width: 100%; width: 100%;">
                                </div>
                                <!-- <div class="field">
                                    <input type="text"
                                           maxlength="1"
                                           @input="MoveEmailInputFocus($event, 1); FormatEmailVerificationCodeToString()"
                                           data-index="0"
                                           ref="email-input-0"
                                           v-on:keypress="NumbersOnly">
                                </div>
                                <div class="field">
                                    <input type="text"
                                           maxlength="1"
                                           @input="MoveEmailInputFocus($event, 1); FormatEmailVerificationCodeToString()"
                                           data-index="1"
                                           ref="email-input-1"
                                           v-on:keypress="NumbersOnly">
                                </div>
                                <div class="field">
                                    <input type="text"
                                           maxlength="1"
                                           @input="MoveEmailInputFocus($event, 1); FormatEmailVerificationCodeToString()"
                                           data-index="2"
                                           ref="email-input-2"
                                           v-on:keypress="NumbersOnly">
                                </div>
                                <div class="field">
                                    <input type="text"
                                           maxlength="1"
                                           @input="MoveEmailInputFocus($event, 1); FormatEmailVerificationCodeToString()"
                                           data-index="3"
                                           ref="email-input-3"
                                           v-on:keypress="NumbersOnly">
                                </div>
                                <div class="field">
                                    <input type="text"
                                           maxlength="1"
                                           @input="MoveEmailInputFocus($event, 1); FormatEmailVerificationCodeToString()"
                                           data-index="4"
                                           ref="email-input-4"
                                           v-on:keypress="NumbersOnly">
                                </div>
                                <div class="field">
                                    <input type="text"
                                           maxlength="1"
                                           @input="MoveEmailInputFocus($event, 1); FormatEmailVerificationCodeToString()"
                                           data-index="5"
                                           ref="email-input-5"
                                           v-on:keypress="NumbersOnly">
                                </div> -->
                            </div>
                            <div class="error-message margin-bottom-regular" v-if="emailVerificationCodeErrorMessage != ''">
                                {{emailVerificationCodeErrorMessage}}
                            </div>
                            <div class="form-actions-wrapper flex flex-row align-center space-between">
                                <div class="resend-code-button-wrapper">
                                    <div class="resend-code-button" @click="ResendCode('email')">
                                        <div class="resend-code-button-icon">
                                            <i class="bi bi-arrow-clockwise"></i>
                                        </div>
                                        <div class="resend-code-button-text">
                                            Resend Code
                                        </div>  
                                    </div>
                                </div>
                                <div class="submit-code-button-wrapper">
                                    <div class="submit-code-button" @click="SubmitEmailVerificationCode">
                                        Submit
                                    </div>
                                </div>
                            </div>
                            <p v-if="resendEmailCode">
                                We have sent a new code to <b>{{primaryContact.email}}</b>
                            </p>
                            <div class="loading-animation-wrapper" v-if="verifyingEmail">
                                <div class="loading-message">Verifying email...</div>
                                <SpinningDotsLoader :color="'#00bcc5'" :scale="0.5"></SpinningDotsLoader>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <!-- SMS Verification Form -->
        <section class="poc-trial-form-sms-verification-section" v-if="showSMSVerificationForm">
            <div class="form-wrapper">
                <form class="poc-trial-form-sms" ref="smsCodeForm">
                    <div class="form-section m-0">
                        <div class="back-button-wrapper flex flex-row align-center margin-bottom-regular"
                             @click="showSMSVerificationForm = false; showPOCForm = true;">
                            <div class="back-button-icon"><i class="bi bi-arrow-left-short"></i></div>
                            <div class="back-button-text">Go Back</div>
                        </div>
                        <div class="fields-heading-wrapper flex flex-row align-center">
                            <i class="bi bi-phone"></i>
                            <div class="fields-heading">SMS Verification</div>
                        </div>
                        <div class="tooltip">
                            We have sent a six digit code to the Primary Contact's mobile number <b>{{primaryContact.areaCode}} {{primaryContact.mobile}}</b>
                            <br><br>
                            Enter the code here to verify the mobile number.
                        </div>
                        <div class="sms-code">
                            <div class="multiple-fields space-between">
                                <div class="field" style="flex: 1;">
                                    <input type="text" maxlength="6" v-model="mobileVerificationCode" style="max-width: 100%; width: 100%;">
                                </div>
                                <!-- <div class="field">
                                    <input  type="text"
                                            maxlength="1"
                                            @input="MoveSMSInputFocus($event, 1)"
                                            data-index="0"
                                            ref="sms-input-0">
                                </div>
                                <div class="field">
                                    <input  type="text"
                                            maxlength="1"
                                            @input="MoveSMSInputFocus($event, 1)"
                                            data-index="1"
                                            ref="sms-input-1">
                                </div>
                                <div class="field">
                                    <input  type="text"
                                            maxlength="1"
                                            @input="MoveSMSInputFocus($event, 1)"
                                            data-index="2"
                                            ref="sms-input-2">
                                </div>
                                <div class="field">
                                    <input  type="text"
                                            maxlength="1"
                                            @input="MoveSMSInputFocus($event, 1)"
                                            data-index="3"
                                            ref="sms-input-3">
                                </div>
                                <div class="field">
                                    <input  type="text"
                                            maxlength="1"
                                            @input="MoveSMSInputFocus($event, 1)"
                                            data-index="4"
                                            ref="sms-input-4">
                                </div>
                                <div class="field">
                                    <input  type="text"
                                            maxlength="1"
                                            @input="MoveSMSInputFocus($event, 1)"
                                            data-index="5"
                                            ref="sms-input-5">
                                </div> -->
                            </div>
                            <div class="error-message margin-bottom-regular" v-if="mobileVerificationCodeErrorMessage != ''">
                                {{mobileVerificationCodeErrorMessage}}
                            </div>
                            <div class="form-actions-wrapper flex flex-row align-center space-between">
                                <div class="resend-code-button-wrapper">
                                    <div class="resend-code-button" @click="ResendCode('mobile')">
                                        <div class="resend-code-button-icon">
                                            <i class="bi bi-arrow-clockwise"></i>
                                        </div>
                                        <div class="resend-code-button-text">
                                            Resend Code
                                        </div>  
                                    </div>
                                </div>
                                <div class="submit-code-button-wrapper">
                                    <div class="submit-code-button" @click="SubmitMobileVerificationCode">
                                        Submit
                                    </div>
                                </div>
                            </div>
                            <p v-if="resendMobileCode">
                                We have sent a new code to <b>{{primaryContact.areaCode}} {{primaryContact.mobile}}</b>
                            </p>
                            <div class="loading-animation-wrapper" v-if="verifyingSMS">
                                <div class="loading-message">Verifying mobile...</div>
                                <SpinningDotsLoader :color="'#00bcc5'" :scale="0.5"></SpinningDotsLoader>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <!-- Successful Submission & Verification Screen -->
        <section class="success-section" v-if="showSuccessView">
            <div class="container">
                <div class="success-message-wrapper">
                    <div class="heading">
                        Your submission has been successful!
                    </div>
                    <div class="sub-heading">
                        Thank you! We have received your submission, and will be in touch with the next steps.
                    </div>
                    <div class="return-home-button-wrapper">
                        <router-link :to="{ name: 'home' }" class="return-home-button">
                            Return Home
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <!-- Notification Banner Test -->
        <!-- <Notification type="error">ERROR</Notification> -->
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import colorpicker from "../components/colorpicker/colorpicker.vue";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import SpinningDotsLoader from "../components/animations/loaders/SpinningDotsLoader.vue";
import axios from "axios";
import AreaCodeDropdown from "../components/area-code-dropdown/AreaCodeDropdown.vue";
// import Notification from "../notification-banner/Notification.vue";

dayjs.extend(advancedFormat);

let primaryColour = {
  hex: '#00BCC5'
}
let secondaryColour = {
  hex: '#732F5B'
}
let primaryContactDate = new Date();

let currentNow = new Date();

let startDate = new Date(Math.floor(currentNow.getTime() / (1000 * 60 * 1)) * (1000 * 60 * 1));
let endDate = new Date(Math.floor(currentNow.getTime() / (1000 * 60 * 1)) * (1000 * 60 * 1));

let pocTimelineStartDateState = {
    disabledDates: {
        to: startDate,
        from: dayjs(startDate).add('1', 'year').toDate()
    }
}
let pocTimelineEndDateState = {
    disabledDates: {
        to: dayjs(startDate).add('14', 'day').toDate(),
        from: dayjs(startDate).add('14', 'day').add('1', 'year').toDate()
    }
}

export default {
    components: {
        Header,
        Datepicker,
        vSelect,
        colorpicker,
        SpinningDotsLoader,
        AreaCodeDropdown,
        // Notification
    },
    metaInfo() {
        return {
            title: 'POC Trial | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: 'A free of any obligation opportunity to explore our application. We have designed our UI to need no pre-requisite training for a user to be able to build and manage workloads across mutliple clouds both public and private. During your trial you will be connected directly to our infrastructure sandbox, so completely autonomous from any production infrastructure.'
                },
                {
                    property: 'og:title',
                    content: 'POC Trial | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: 'A free of any obligation opportunity to explore our application. We have designed our UI to need no pre-requisite training for a user to be able to build and manage workloads across mutliple clouds both public and private. During your trial you will be connected directly to our infrastructure sandbox, so completely autonomous from any production infrastructure.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/platform/poc-trial'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/platform/poc-trial'
                }
            ]
        }
    },
    data: () => ({
        primaryColour,
        secondaryColour,
        showPrimaryColourPicker: false, showSecondaryColourPicker: false,
        primeAdminUsers: 0, adminUsers: 0, standardUsers: 0, partialUsers: 0,
        showContactDatepicker: false, showStartDatepicker: false, showEndDatepicker: false,
        primaryContactDate, primaryContactDateString: "",
        startDate, startDateString: "", endDate, endDateString: "",
        showPOCForm: true, showSMSVerificationForm: false, showEmailVerificationForm: false, showSuccessView: false,
        verifyingPOCForm: false, verifyingSMS: false, verifyingEmail: false,
        verifyPOCFormSuccess: false, verifySMSSuccess: false, verifyEmailSuccess: false,
        pocTimelineStartDateState, pocTimelineEndDateState, pocTimelineEndDateExceedingTrialLength: false,
        emailVerificationCode: "", emailVerificationCodeErrorMessage: "",
        mobileVerificationCode: "", mobileVerificationCodeErrorMessage: "",
        pocOptionalCloudEnvironmentSelected: false,
        // POC Form Validation
        agreedToTerms: false, agreedToPrivacyPolicy: false,
        confidentialityAgreementErrorMessage: "", privacyPolicyAgreementErrorMessage: "",
        resendEmailCode: false, resendMobileCode: false,
        dropdownValue: "none",
        primaryContact: {
            fullName: "",
            email: "",
            mobile: "",
            date: "",
            areaCode : "",
        },
        companyDetails: {
            name: "",
            address: {
                line1: "",
                line2: "",
                line3: ""
            },
            zipCode: ""
        },
        setupDetails: {
            subdomain: "",
            domain: ".illapa.cloud",
            serviceName: "",
            startDate: "",
            endDate: "",
            objectives: ""
        },
        users: [],
        clouds: [
            {
                name: "vmware-vcenter",
                status: true
            },
            {
                name: "nutanix-ahv",
                status: true
            },
            {
                name: "aws",
                status: false
            },
            {
                name: "azure",
                status: false
            }
        ],

        submissionErrors: false,
        errorMessages: {
            primaryContact: {
                fullName: "",
                email: "",
                areaCode: "",
                mobile: ""
            },
            companyDetails: {
                name: "",
                line1: "",
                zipCode: ""
            },
            setupDetails: {
                subdomain: "",
                serviceName: "",
                objectives: ""
            },
            users: []
        }
    }),
    created() {
        const startDateObject = dayjs(this.startDate);
        const calculatedEndDate = startDateObject.add('14', 'day');
        this.startDateString = startDateObject.format("Do MMMM YYYY");
        this.endDateString = calculatedEndDate.format("Do MMMM YYYY");
        this.setupDetails.startDate = startDateObject.format("Do MMMM YYYY");
        this.endDate = calculatedEndDate.toDate();

        this.primaryContactDateString = dayjs(this.primaryContactDate).format("Do MMMM YYYY");
        this.primaryContact.date = String(this.primaryContactDateString);
    },
    mounted: function () {
        this.$root.$on('route-change', (routeName) => {
            if (this.$route.name == routeName) {
                // Change the booleans here
                this.verifyingEmail = false;
                this.showEmailVerificationForm = false;
                this.verifyingSMS = false;
                this.showSMSVerificationForm = false;
                this.verifyingPOCForm = false;
                this.showSuccessView = false;
                this.showPOCForm = true;
            }
        })
    },
    watch: {
        primaryContactDate: function() {
            let date = this.primaryContactDate;
            date = dayjs(date).format("Do MMMM YYYY");
            this.primaryContactDateString = date;
        },
        startDate: function() {
            const startDateObject = dayjs(this.startDate);
            const calculatedEndDate = startDateObject.add('14', 'day');
            this.startDateString = startDateObject.format("Do MMMM YYYY");
            this.endDateString = calculatedEndDate.format("Do MMMM YYYY");
            this.endDate = calculatedEndDate.toDate();
            this.setupDetails.startDate = startDateObject.format("Do MMMM YYYY");
        },
        endDate: function() {
            // Calculate the difference between the start and end date in 'days'
            const startDateObject = dayjs(this.startDate);
            const calculatedEndDate = startDateObject.add('14', 'day');
            const endDateObject = dayjs(this.endDate);
            this.endDateString = endDateObject.format("Do MMMM YYYY");
            this.setupDetails.endDate = endDateObject.format("Do MMMM YYYY");

            const difference = startDateObject.diff(endDateObject, 'day');
            if (Math.abs(difference) > 14) {
                this.pocTimelineEndDateExceedingTrialLength = true;
            } else {
                this.pocTimelineEndDateExceedingTrialLength = false;
            }

            this.pocTimelineEndDateState.disabledDates.to = calculatedEndDate.toDate();
            this.pocTimelineEndDateState.disabledDates.from = calculatedEndDate.add('1', 'year').toDate();
        },
        // Validate POC Form Data
        // - Primary Contact
        'primaryContact.fullName': function() {
            this.errorMessages.primaryContact.fullName = "";
            this.ValidateGeneric(this.primaryContact.fullName, "primaryContact", "fullName", false);
        },
        'primaryContact.email': function() {
            this.errorMessages.primaryContact.email = "";
            this.newTotalEmailValidation();
            this.ValidateGeneric(this.primaryContact.email, "primaryContact", "email", false);
        },
        'primaryContact.mobile': function() {
            this.errorMessages.primaryContact.mobile = "";
            this.newTotalMobileValidation();
            this.ValidateGeneric(this.primaryContact.mobile, "primaryContact", "mobile", false);
        },
        'primaryContact.areaCode': function() {
            this.newTotalMobileValidation();
            this.ValidateAreaCode(this.primaryContact.areaCode, "primaryContact", "areaCode", false);
            this.ValidateGeneric(this.primaryContact.areaCode, "primaryContact", "areaCode", false);
        },
        // - Company Details
        'companyDetails.name': function() {
            this.errorMessages.companyDetails.name = "";
            this.ValidateGeneric(this.companyDetails.name, "companyDetails", "name");
        },
        'companyDetails.zipCode': function() {
            this.errorMessages.companyDetails.zipCode = "";
            this.ValidateGeneric(this.companyDetails.zipCode, "companyDetails", "zipCode");
        },
        'companyDetails.address.line1': function() {
            this.errorMessages.companyDetails.line1 = "";
            this.ValidateGeneric(this.companyDetails.address.line1, "companyDetails", "line1");
        },
        // - Setup Details
        'setupDetails.subdomain': function() {
            this.ValidateGeneric(this.setupDetails.subdomain, "setupDetails", "subdomain");
            this.ValidateSubdomain(this.setupDetails.subdomain, "setupDetails", "subdomain");
        },
        'setupDetails.serviceName': function() {
            this.errorMessages.setupDetails.serviceName = "";
            this.ValidateGeneric(this.setupDetails.serviceName, "setupDetails", "serviceName");
        },
        'setupDetails.objectives': function() {
            this.errorMessages.setupDetails.objectives = "";
            this.ValidateGeneric(this.setupDetails.objectives, "setupDetails", "objectives");
        },
        'setupDetails.startDate': function() {
            this.ValidateGeneric(this.setupDetails.startDate, "setupDetails", "startDate");
        },
        'setupDetails.endDate': function() {
            this.ValidateGeneric(this.setupDetails.endDate, "setupDetails", "endDate");
        },
        agreedToTerms: function() {
            this.ValidateTermsAgreement();
        },
        agreedToPrivacyPolicy: function() {
            this.ValidatePrivacyPolicyAgreement();
        },
    },
    computed: {
        primaryColourComputed() {
            return this.primaryColour.hex;
        },
        secondaryColourComputed() {
            return this.secondaryColour.hex;
        }
    },
    methods: {
        DisableCheckboxLabelClick(e) {
            e.preventDefault();
        },
        ResendCode(form) {
            if (form === 'email') {
                this.resendEmailCode = true;
                this.SubmitPOCTrialForm();
            } else if (form === 'mobile') {
                this.resendMobileCode = true;
                this.SubmitEmailVerificationCode();
            }
        },
        OpenLinkInNewTab(name, param) {
            const route = this.$router.resolve({ name: name, query: { legal: param } });
            window.open(route.href, '_blank');
        },
        SelectedOptionalCloudEnvironment(index) {
            this.clouds[index].status = !this.clouds[index].status;
            if(!this.clouds[2].status && !this.clouds[3].status) {
                this.pocOptionalCloudEnvironmentSelected = false;
            } else {
                this.pocOptionalCloudEnvironmentSelected = true;
            }
        },
        selectCountry(areaCode, index, user) {
            if (user) {
                for (let i = 0; i < this.users.length; i++) {
                    if (i == index) {
                        this.users[i].areaCode = areaCode;
                        // const ref = this.$refs[`userAreaCodeDropdown-${index}`];
                        switch(areaCode) {
                            case '+36':
                                this.users[i].dropdownValue = 'hu';
                                break;
                            case '+39':
                                this.users[i].dropdownValue = 'it';
                                break;
                            case '+41':
                                this.users[i].dropdownValue = 'ch';
                                break;
                            case '+44':
                                this.users[i].dropdownValue = 'gb';
                                break;
                            case '+46':
                                this.users[i].dropdownValue = 'se';
                                break;
                            case '+48':
                                this.users[i].dropdownValue = 'pl';
                                break;
                            case '+56':
                                this.users[i].dropdownValue = 'cl';
                                break;
                            case '+61':
                                this.users[i].dropdownValue = 'au';
                                break;
                            case '+63':
                                this.users[i].dropdownValue = 'ph';
                                break;
                            case '+65':
                                this.users[i].dropdownValue = 'sg';
                                break;
                            case '+351':
                                this.users[i].dropdownValue = 'pt';
                                break;
                            case '+370':
                                this.users[i].dropdownValue = 'lt';
                                break;
                            case '+372':
                                this.users[i].dropdownValue = 'ee';
                                break;
                            case '+420':
                                this.users[i].dropdownValue = 'cz';
                                break;
                            case '+421':
                                this.users[i].dropdownValue = 'sk';
                                break;
                            case '+852':
                                this.users[i].dropdownValue = 'hk';
                                break;
                            case '+972':
                                this.users[i].dropdownValue = 'il';
                                break;
                            default:
                                break;
                        }
                        this.ValidateAreaCode(this.users[i].areaCode, "users", "areaCode", true, i);
                    }
                }
                this.newTotalMobileValidation();
            } else {
                this.primaryContact.areaCode = areaCode;
                // const ref = this.$refs.primaryContactAreaCodeDropdown;
                switch(areaCode) {
                    case '+36':
                        this.dropdownValue = 'hu';
                        break;
                    case '+39':
                        this.dropdownValue = 'it';
                        break;
                    case '+41':
                        this.dropdownValue = 'ch';
                        break;
                    case '+44':
                        this.dropdownValue = 'gb';
                        break;
                    case '+46':
                        this.dropdownValue = 'se';
                        break;
                    case '+48':
                        this.dropdownValue = 'pl';
                        break;
                    case '+56':
                        this.dropdownValue = 'cl';
                        break;
                    case '+61':
                        this.dropdownValue = 'au';
                        break;
                    case '+63':
                        this.dropdownValue = 'ph';
                        break;
                    case '+65':
                        this.dropdownValue = 'sg';
                        break;
                    case '+351':
                        this.dropdownValue = 'pt';
                        break;
                    case '+370':
                        this.dropdownValue = 'lt';
                        break;
                    case '+372':
                        this.dropdownValue = 'ee';
                        break;
                    case '+420':
                        this.dropdownValue = 'cz';
                        break;
                    case '+421':
                        this.dropdownValue = 'sk';
                        break;
                    case '+852':
                        this.dropdownValue = 'hk';
                        break;
                    case '+972':
                        this.dropdownValue = 'il';
                        break;
                    default:
                        break;
                }
                this.ValidateAreaCode(this.primaryContact.areaCode, "primaryContact", "areaCode", false);
                this.newTotalMobileValidation();
            }
        },
        AddUser() {
            this.users.push({
                name: "",
                email: "",
                areaCode: "",
                mobile: "",
                role: "",
                dropdownValue: "none",
                inputMade: false,
                primaryContact: false
            });
            this.errorMessages.users.push({
                fullName: "",
                email: "",
                areaCode: "",
                mobile: "",
                role: ""
            });
        },
        DeleteUser(index) {
            this.users.splice(index, 1);
            this.errorMessages.users.splice(index, 1);
            // Run the unique validation for both the email and mobile, for both the primary contact and the users
            // for (let i = 0; i < this.users.length; i++) {
            //     this.ValidateUserMobileIsUnique(this.users[i].mobile, i);
            // }
            this.newTotalMobileValidation();
            this.newTotalEmailValidation();
            this.errorMessages.primaryContact.mobile = "";
            //this.ValidatePrimaryContactMobileIsUnique(this.primaryContact.mobile);
        },
        ChangeInputMade(index) {
            for (let i = 0; i < this.users.length; i++) {
                if (i === index) {
                    this.users[i].inputMade = true;
                }
            }
        },
        OpenPDF() {
            window.open("/confidentiality.pdf", '_blank').focus();
        },
        async SubmitPOCTrialForm() {
            this.verifyingPOCForm = true;
            try {
                let sentUsers = this.CleanUpAreaCodes();
                const submitRequest = await axios.post(`https://${location.host}/api/pocTrial/new`, {
                    primaryContact: {
                        fullName: this.primaryContact.fullName,
                        email: this.primaryContact.email,
                        mobile: String(this.primaryContact.areaCode) + String(this.primaryContact.mobile),
                        date: this.primaryContact.date
                    },
                    "companyDetails": this.companyDetails,
                    "setupDetails": this.setupDetails,
                    "users": sentUsers,
                    "clouds": this.clouds,
                });
                // console.log(submitRequest);

                if (submitRequest.data.status === "success") {
                    this.ProceedToEmailVerification();
                }
            } catch(err) {
                console.log(err);
                console.log(err.response.data);
                this.verifyingPOCForm = false;
            }
        },
        async SubmitEmailVerificationCode() {
            this.verifyingEmail = true;
            try {
                const submitRequest = await axios.post(`https://${location.host}/api/pocTrial/checkEmailCode`, {
                    "subdomain": this.setupDetails.subdomain,
                    "authCode": this.emailVerificationCode
                });
                // console.log(submitRequest);

                if (submitRequest.data.status === "success") {
                    this.ProceedToSMSVerification();
                    this.resendEmailCode = false;
                }
            } catch (err) {
                console.log(err.response.data);
                if (err.response.data.message === "Too many incorrect attempts, form deleted") {
                    this.emailVerificationCodeErrorMessage = "Too many incorrect attempts at authenticating your email address, please try again.";
                    this.showEmailVerificationForm = false;
                    this.showPOCForm = true;
                }
                if (err.response.data.message === "2FA does not match") {
                    this.emailVerificationCodeErrorMessage = "Code does not match, please try again.";
                }
                this.verifyingEmail = false;
            }
        },
        async SubmitMobileVerificationCode() {
            this.verifyingSMS = true;
            try {
                const submitRequest = await axios.post(`https://${location.host}/api/pocTrial/checkTextCode`, {
                    "subdomain": this.setupDetails.subdomain,
                    "authCode": this.mobileVerificationCode
                });
                // console.log(submitRequest);

                if (submitRequest.status === 200) {
                    this.ProceedToSuccessScreen();
                    this.resendMobileCode = false;
                }
            } catch (err) {
                console.log(err.response.data);
                if (err.response.data.message === "Too many incorrect attempts, form deleted") {
                    this.mobileVerificationCodeErrorMessage = "Too many incorrect attempts at authenticating your mobile number, please try again.";
                    this.showSMSVerificationForm = false;
                    this.showPOCForm = true;
                }
                if (err.response.data.message === "2FA does not match") {
                    this.mobileVerificationCodeErrorMessage = "Code does not match, please try again.";
                }
                this.verifyingSMS = false;
            }
        },
        CleanUpAreaCodes() {
            let newArr = JSON.parse(JSON.stringify(this.users));
            for (let user of newArr){
                user.mobile = String(user.areaCode) + String(user.mobile);
                delete user.areaCode;
                delete user.dropdownValue;
                delete user.inputMade;
                delete user.primaryContact;
            }
            return newArr;
        },
        AddPrimaryContactDataToUsers() {
            this.users.push({
                name: this.primaryContact.fullName,
                email: this.primaryContact.email,
                areaCode: this.primaryContact.areaCode,
                mobile: this.primaryContact.mobile,
                role: "Prime Admin",
                dropdownValue: this.primaryContact.areaCode,
                inputMade: false,
                primaryContact: true
            });
            this.errorMessages.users.push({
                fullName: "",
                email: "",
                areaCode: "",
                mobile: "",
                role: ""
            });
        },
        ProceedToEmailVerification() {
            this.verifyPOCFormSuccess = true;
            this.verifyingPOCForm = false;
            this.showPOCForm = false;
            this.showEmailVerificationForm = true;
            // Clear the input values
            this.emailVerificationCode = "";
            this.emailVerificationCodeErrorMessage = "";
        },
        MoveEmailInputFocus(event, maxLength) {
            if (event.target.value.length === maxLength) {
                const nextInput = this.$refs?.[
                    `email-input-${Number(event.target.dataset.index) + 1}`
                ];
                if (nextInput) {
                    nextInput.focus();
                }
                if (Number(event.target.dataset.index == 5)) {
                    this.FormatEmailVerificationCodeToString();
                    // this.SubmitEmailVerificationCode(this.emailVerificationCode);
                }
            }
        },
        FormatEmailVerificationCodeToString() {
            for (let i = 0; i < 6; i++) {
                const input = this.$refs?.[`email-input-${i}`];
                this.emailVerificationCode += input.value;
            }
        },
        VerifyEmailCode() {
            this.verifyingEmail = true;
            setTimeout(this.ProceedToSMSVerification, 5000);
        },
        ProceedToSMSVerification() {
            this.verifyEmailSuccess = true;
            this.verifyingEmail = false;
            this.showEmailVerificationForm = false;
            this.showSMSVerificationForm = true;
            // Clear the input values
            // const inputElements = this.$refs.smsCodeForm.getElementsByTagName("input");
            // for (let element of inputElements) {
            //     element.value = '';
            // }
        },
        MoveSMSInputFocus(event, maxLength) {
            if (event.target.value.length === maxLength) {
                const nextInput = this.$refs?.[
                    `sms-input-${Number(event.target.dataset.index) + 1}`
                ];
                if (nextInput)
                    nextInput.focus();
                if (Number(event.target.dataset.index == 5))
                    this.VerifySMSCode();
            }
        },
        VerifySMSCode() {
            this.verifyingSMS = true;
            setTimeout(this.ProceedToSuccessScreen, 5000);
        },
        ProceedToSuccessScreen() {
            this.verifySMSSuccess = true;
            this.verifyingSMS = false;
            this.showSMSVerificationForm = false;
            this.showSuccessView = true;
        },
        ScrollToTop() {
            window.scroll(0,0);
        },
        // POC Form Validation
        ValidateGeneric(value, section, errorValue, isUser, index) {
            if (isUser) {
                if (!value) {
                    this.errorMessages['users'][index][errorValue] = "This field is required."
                }
            }
            if (!value) {
                this.errorMessages[section][errorValue] = "This field is required.";
            }
        },
        newTotalEmailValidation(){
            this.resetEmailErrors();

            this.newIsEmailUnique();
            
            this.newPrimaryEmailValidation();

            for(let i = 0; i < this.users.length; i++){
                this.newUserEmailValidation(this.users[i].email, i);
            }

           
        },
        //Validation for User Email
        newUserEmailValidation(userEmail, index){
            const isValidGen = this.newValidGen(userEmail);
            if(!isValidGen){
                //this.errorMessages.users[index].email = "This field is required.";
                return;
            }

            const isEmail = this.newValidateEmail(userEmail)
            if(!isEmail){
                this.errorMessages.users[index].email = "This email address is invalid";
            }

            
        },
        //Validation just for Primary Email
        newPrimaryEmailValidation() {

            const isValidGen = this.newValidGen(this.primaryContact.email);
            if(!isValidGen){
                //this.errorMessages.primaryContact.email = "This field is required.";
                return;
            }

            const isEmail = this.newValidateEmail(this.primaryContact.email)
            if(!isEmail){
                this.errorMessages.primaryContact.email = "This email address is invalid";
            }

            
        },
        //Reset Email Errors
        resetEmailErrors(){
            if(this.primaryContact.email != ""){
                this.errorMessages.primaryContact.email = "";
            }

            for(let i = 0; i < this.users.length; i++){
                if(this.users[i].email != ""){
                    this.errorMessages.users[i].email = "";
                }
            }
        },
        //Check all Emails values
        newIsEmailUnique(){
            let userEmails = this.users.map(user => user.email);
            let primaryEmail = this.primaryContact.email;

            if(userEmails.includes(primaryEmail) && primaryEmail != ""){
                this.errorMessages.primaryContact.email = "Please provide a unique email address";
            }


            for(let i = 0; i < this.users.length; i++){
                if(this.users[i].email == ""){ continue }
                let match = false;
                for(let j = 0; j < this.users.length; j++){
                    if(i == j){ continue; }
                    if(this.users[i].email == this.users[j].email){
                        match = true;
                    }
                }
                
                if(this.users[i].email == this.primaryContact.email){
                    match = true;
                }

                if(match){
                    this.errorMessages.users[i].email = "Please provide a unique email address";
                }
            }
        },
        newValidateEmail(value) {
            if(new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.(com|cloud|za|co.uk|net|biz|org|int|tel|xxx|info|px|tv|mobi|travel|com.au|ae|ca|cz|dk|com.eg|eu|uk|hk|jp|no|nz|sg|sk)$").test(value)){
                return true
            }
            return false;
        },
        newValidGen(value) {
            if(!value){
                return false;
            }
            return true;
        },
        ValidateEmail(value, section, errorValue, isUser, index) {
            if (isUser) {
                if (new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.(com|cloud|za|co.uk|net|biz|org|int|tel|xxx|info|px|tv|mobi|travel|com.au|ae|ca|cz|dk|com.eg|eu|uk|hk|jp|no|nz|sg|sk)$").test(value)) {
                    this.errorMessages['users'][index][errorValue] = "";
                } else {
                    this.errorMessages['users'][index][errorValue] = "This email address is invalid.";
                }
                if (!value) {
                    this.errorMessages['users'][index][errorValue] = "An email address is required.";
                }
            }
            if (new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.(com|cloud|za|co.uk|net|biz|org|int|tel|xxx|info|px|tv|mobi|travel|com.au|ae|ca|cz|dk|com.eg|eu|uk|hk|jp|no|nz|sg|sk)$").test(value)) {
                this.errorMessages[section][errorValue] = "";
            } else {
                this.errorMessages[section][errorValue] = "This email address is invalid.";
            }
            if (!value) {
                this.errorMessages[section][errorValue] = "An email address is required.";
            }
        },
        newTotalMobileValidation(){
            let primaryNumber = String(this.primaryContact.areaCode) + String(this.primaryContact.mobile);
            let userNumbers = [];

            for(let user of this.users){
                userNumbers.push(String(user.areaCode) + String(user.mobile));
            }
            this.resetMobileErrors();
            this.newIsMobileUnique(primaryNumber, userNumbers);
           

            this.newPrimaryMobileValidation();
            for(let i = 0; i < this.users.length; i++){
                this.newUserMobileValidation(this.users[i].mobile, i);
            }

        },
        newIsMobileUnique(primaryNumber, userNumbers){
            
            if(userNumbers.includes(primaryNumber) && primaryNumber != ""){
                this.errorMessages.primaryContact.mobile = "Please provide a unique mobile number";
            }

            for(let i = 0; i < userNumbers.length; i++){
                if(userNumbers[i] == ""){ continue }
                let match = false;
                for(let j = 0; j < userNumbers.length; j++){
                    if(i==j) continue;
                    if(userNumbers[i] == userNumbers[j]){
                        match = true;
                    }
                }

                if(userNumbers[i] == primaryNumber){
                    match = true;
                }

                if(match){
                    this.errorMessages.users[i].mobile = "Please provide a unique mobile number";
                }
            }
        },
        resetMobileErrors(){
            if(this.primaryContact.mobile != ""){
                this.errorMessages.primaryContact.mobile = "";
            }

            for(let i = 0; i < this.users.length; i++){
                if(this.users[i].mobile != ""){
                    this.errorMessages.users[i].mobile = "";
                }
            }
        },
        newValidateMobile(value){
            if(new RegExp("^[0-9]{10,11}$").test(value)){
                return true;
            }
            return false;
        },
        newPrimaryMobileValidation(){
            const isValidGen = this.newValidGen(this.primaryContact.mobile);

            if(!isValidGen){
                //this.errorMessages.primaryContact.mobile = "This field is required";
                return;
            }

            const isMobile = this.newValidateMobile(this.primaryContact.mobile);
            if(!isMobile){
                this.errorMessages.primaryContact.mobile = "This mobile number is invalid";
            }

            
        },
        newUserMobileValidation(userMobile, index){

            const isValidGen = this.newValidGen(userMobile);

            if(!isValidGen){
                //this.errorMessages.users[index].mobile = "This field is required";
                return;
            }

            const isMobile = this.newValidateMobile(userMobile);
            if(!isMobile){
                this.errorMessages.users[index].mobile = "This mobile number is invalid";
            }

            
        },
        ValidatePrimaryContactMobileIsUnique(value) {
            let newValue = String(this.primaryContact.areaCode + value);

            let match = false;

            if (this.users.length > 0) {
                for (let i = 0; i < this.users.length; i++) {
                    let userMobile = String(this.users[i].areaCode + this.users[i].mobile);
                    if (newValue === userMobile && userMobile != '') {
                        match = true;
                        this.errorMessages['users'][i]['mobile'] = "Please provide a unique mobile number.";
                    } else {
                        // this.errorMessages['users'][i]['mobile'] = "";
                        this.ValidateMobile(this.users[i].mobile, "users", "mobile", true, i);
                        this.ValidateGeneric(this.users[i].mobile, "users", "mobile", true, i);
                    }
                }
            }
            if (match) {
                this.errorMessages.primaryContact.mobile = "Please provide a unique mobile number.";
            } else {
                this.errorMessages.primaryContact.mobile = "";
            }
        },
        ValidateUserMobileIsUnique(value, index) {
            let newValue = String(this.users[index].areaCode + value);
            let primaryContactMobile = String(this.primaryContact.areaCode + this.primaryContact.mobile);
            
            if (newValue === primaryContactMobile && primaryContactMobile != '') {
                this.errorMessages['users'][index]['mobile'] = "Please provide a unique mobile number.";
                this.errorMessages['primaryContact']['mobile'] = "Please provide a unique mobile number.";
            } else {
                this.ValidatePrimaryContactMobileIsUnique(this.primaryContact.mobile);
                this.ValidateMobile(this.primaryContact.mobile, "primaryContact", "mobile");
                this.ValidateGeneric(this.primaryContact.mobile, "primaryContact", "mobile");
            }
            
            for (let i = 0; i < this.users.length; i++) {
                if (i === index) { continue; }
                let otherUserMobile = String(this.users[i].areaCode + this.users[i].mobile);
                if (newValue === otherUserMobile && otherUserMobile != '') {
                    // This is working and logs to the console
                    this.errorMessages['users'][index]['mobile'] = "Please provide a unique mobile number.";
                    this.errorMessages['users'][i]['mobile'] = "Please provide a unique mobile number.";
                }
            }
        },
        ValidateMobile(value, section, errorValue, isUser, index) {
            if (isUser) {
                if (new RegExp("^[0-9]{10,11}$").test(value)) {
                    // Can refactor: comment here to allow the otherwise empty block statement
                } else {
                    this.errorMessages['users'][index][errorValue] = "This mobile number is invalid.";
                }
                if (!value) {
                    this.errorMessages['users'][index][errorValue] = "A mobile number is required.";
                }
            }
            if (new RegExp("^[0-9]{10,11}$").test(value)) {
                // Can refactor: comment here to allow the otherwise empty block statement
            } else {
                this.errorMessages[section][errorValue] = "This mobile number is invalid.";
            }
            if (!value) {
                this.errorMessages[section][errorValue] = "A mobile number is required.";
            }
        },
        ValidateAreaCode(value, section, errorValue, isUser, index) {
            if (isUser) {
                if (!value) {
                    this.errorMessages['users'][index][errorValue] = "Please select a country code."
                }
                else{
                    this.errorMessages['users'][index][errorValue] = "";
                }
                
            } else {
                if (!value) {
                    this.errorMessages[section][errorValue] = "Please select a country code."
                } else {
                    this.errorMessages[section][errorValue] = "";
                }
            }
        },
        ValidateUserRole(value, errorValue, isUser, index) {
            if (isUser) {
                if (!value) {
                    this.errorMessages['users'][index][errorValue] = "Please select a role for this user."
                } else {
                    this.errorMessages['users'][index][errorValue] = "";
                }
            }
        },
        ValidateSubdomain(value, section, errorValue) {
            if (new RegExp("^[a-z]{1,63}$").test(value)) {
                this.errorMessages[section][errorValue] = "";
            } else {
                if (value.length > 63) {
                    this.errorMessages[section][errorValue] = "Your subdomain must be between 1 and 63 characters long."
                }
                if (value.search(/[a-z]/) == -1) {
                    this.errorMessages[section][errorValue] = "Your subdomain can only contain lowercase letters."
                }
                if (value.search(/[A-Z]/) > 0) {
                    this.errorMessages[section][errorValue] = "Your subdomain can only contain lowercase letters."
                }
                if (value.search(/[0-9]/) > 0) {
                    this.errorMessages[section][errorValue] = "Your subdomain can only contain lowercase letters."
                }
                if (value.search(/[!@#$%^&*)(][}{+=._-]/) > 0) {
                    this.errorMessages[section][errorValue] = "Your subdomain can only contain lowercase letters."
                }
            }
            if (!value) {
                this.errorMessages[section][errorValue] = "A subdomain is required.";
            }
        },
        ValidateTermsAgreement() {
            this.agreedToTerms ? this.confidentialityAgreementErrorMessage = "" : this.confidentialityAgreementErrorMessage = "Please confirm that you have read, understood and agree to our Confidentiality Terms.";
        },
        ValidatePrivacyPolicyAgreement() {
            this.agreedToPrivacyPolicy ? this.privacyPolicyAgreementErrorMessage = "" : this.privacyPolicyAgreementErrorMessage = "Please confirm that you have read, understood and agree to our Privacy Policy.";
        },
        ValidateAllInputs() {
            // Primary Contact Error Message Validation
            this.ValidateGeneric(this.primaryContact.fullName, "primaryContact", "fullName", false);
            this.ValidateEmail(this.primaryContact.email, "primaryContact", "email", false);
            this.ValidateGeneric(this.primaryContact.email, "primaryContact", "email", false);
            this.ValidateMobile(this.primaryContact.mobile, "primaryContact", "mobile", false);
            this.ValidateGeneric(this.primaryContact.mobile, "primaryContact", "mobile", false);
            this.ValidateAreaCode(this.primaryContact.areaCode, "primaryContact", "areaCode", false);
            this.ValidateGeneric(this.primaryContact.areaCode, "primaryContact", "areaCode", false);

            // Company Details Error Message Validation
            this.ValidateGeneric(this.companyDetails.name, "companyDetails", "name", false);
            this.ValidateGeneric(this.companyDetails.zipCode, "companyDetails", "zipCode", false);
            this.ValidateGeneric(this.companyDetails.address.line1, "companyDetails", "line1", false);

            // Setup Details Error Message Validation
            this.ValidateSubdomain(this.setupDetails.subdomain, "setupDetails", "subdomain");
            this.ValidateGeneric(this.setupDetails.subdomain, "setupDetails", "subdomain", false);
            this.ValidateGeneric(this.setupDetails.serviceName, "setupDetails", "serviceName", false);
            this.ValidateGeneric(this.setupDetails.objectives, "setupDetails", "objectives", false);
            this.ValidateGeneric(this.setupDetails.startDate, "setupDetails", "startDate", false);
            this.ValidateGeneric(this.setupDetails.endDate, "setupDetails", "endDate", false);

            // User Error Message Validation
            for (let i = 0; i < this.users.length; i++) {
                // Name
                this.ValidateGeneric(this.users[i].name, "users", "fullName", true, i);
                // Email
                this.ValidateEmail(this.users[i].email, "users", "email", true, i);
                this.ValidateGeneric(this.users[i].email, "users", "email", true, i);
                // Mobile
                this.ValidateMobile(this.users[i].mobile, "users", "mobile", true, i);
                this.ValidateGeneric(this.users[i].mobile, "users", "mobile", true, i);                
                // Area Code
                this.ValidateAreaCode(this.users[i].areaCode, "users", "areaCode", true, i);
                this.ValidateGeneric(this.users[i].areaCode, "users", "areaCode", true, i);
                // User Role
                this.ValidateUserRole(this.users[i].role, "role", true, i);
                this.ValidateGeneric(this.users[i].role, "users", "role", true, i);
            }

            // User Terms Agreement Validation
            this.ValidateTermsAgreement();
            // Privacy Policy Agreement Validation
            this.ValidatePrivacyPolicyAgreement();

            // Unique Validation
            this.newTotalEmailValidation();
            this.newTotalMobileValidation();

            // this.emailVerificationCodeErrorMessage = "";
            this.submissionErrors = false;
            for (let [key, value] of Object.entries(this.errorMessages)) { // eslint-disable-line no-unused-vars
                let childKey = key;
                if (key == "users") {
                    for (let user of this.errorMessages[key]) {
                        for (let [key, value] of Object.entries(user)) { // eslint-disable-line no-unused-vars
                            if (value != '' || !this.agreedToTerms) {
                                this.submissionErrors = true;
                            }
                        } 
                    }
                } else {
                    for (let [key, value] of Object.entries(this.errorMessages[childKey])) { // eslint-disable-line no-unused-vars
                        if (value != '' || !this.agreedToTerms) {
                            this.submissionErrors = true;
                        }
                        if (this.confidentialityAgreementErrorMessage != '' || this.privacyPolicyAgreementErrorMessage != '') {
                            this.submissionErrors = true;
                        }
                    }
                }
            }

            if (!this.submissionErrors && this.agreedToTerms && this.agreedToPrivacyPolicy) {
                this.SubmitPOCTrialForm();
            }
            else{
                this.ScrollToTop();
            }
        },
        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
}
</script>

<style scoped>
.form-wrapper {
    max-width: 1450px;
    padding: 0 20px;
}
.poc-trial-form-section {
    background-color: #f4f7f9;
}
.poc-trial-form-section .form-wrapper {
    /* transform: translateY(-100px); */
    margin: 0 auto;
}
.poc-trial-form-sms-verification-section .form-wrapper,
.poc-trial-form-email-verification-section .form-wrapper {
    margin: 100px auto;
}
.form-wrapper form {
    display: flex;
    flex-flow: column nowrap;
    padding: 25px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 3px 52px 0 rgba(0,0,0,0.08);
}
.form-wrapper .form-section {
    margin-bottom: 20px;
}
.form-wrapper .form-section p {
    margin-bottom: 0;
    color: var(--text-dark-terniary);
    line-height: 1.5;
}
.form-wrapper .form-section .tooltip {
    margin-bottom: 1rem;
    color: var(--text-dark-terniary);
    line-height: 1.5;
}
.form-wrapper .form-section .resend-code-button-wrapper {
    cursor: pointer;
    display: inline-block;
}
.form-wrapper .form-section .resend-code-button-wrapper .resend-code-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 4px;
    color: #f26565;
    font-weight: 500;
}
.form-wrapper .form-section .resend-code-button-wrapper .resend-code-button .resend-code-button-icon {
    font-size: 20px;
    margin-right: 10px;
}
@supports (-webkit-text-stroke: 1px #f26565) {
    .form-wrapper .form-section .resend-code-button-wrapper .resend-code-button .resend-code-button-icon {
        -webkit-text-stroke: 1px #f26565
    }
}
.form-wrapper .form-section .submit-code-button-wrapper .submit-code-button {
    padding: 10px 20px;
    background-color: var(--brand-accent-blue);
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: box-shadow 0.15s ease;
}
.form-wrapper .form-section .submit-code-button-wrapper .submit-code-button:hover {
    box-shadow: 0 3px 15px 0 #007bfc96;
}
.form-wrapper .loading-animation-wrapper {
    text-align: center;
    margin: 20px 0;
}
.form-wrapper .loading-animation-wrapper .loading-message {
    color: var(--brand-primary-blue);
}
.form-wrapper .form-heading .heading {
    font-size: 28px;
    font-weight: 500;
    padding: 10px 0;
    color: var(--dark-default);
    border-bottom: 1px solid #0f1d2926;
    margin-bottom: 40px;
}
.form-wrapper .poc-trial-form-sms,
.form-wrapper .poc-trial-form-email {
    max-width: 500px;
    margin: 0 auto;
}
.form-wrapper .back-button-wrapper {
    cursor: pointer;
}
.form-wrapper .back-button-wrapper .back-button-icon {
    margin-right: 10px;
    font-size: 22px;
}
.form-wrapper .back-button-wrapper .back-button-text {
    font-weight: 500;
}
.form-wrapper .poc-trial-form .add-user {
    font-family: inherit;
    font-size: 16px;
    padding: 10px 15px;
    max-width: 110px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--brand-accent-blue);
    color: white;
    transition: box-shadow 0.2s ease;
}
.form-wrapper .poc-trial-form .add-user:hover {
    box-shadow: 0 9px 30px 0 #007bfc4d;
}
.form-wrapper .fields-heading-wrapper i {
    margin-bottom: 1rem;
    margin-right: 10px;
    font-size: 20px;
    color: var(--brand-primary-blue);
}
.form-wrapper .fields-heading {
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0;
    color: var(--brand-primary-blue);
    border-bottom: 2px solid rgba(0, 188, 197, 0.3);
    width: 100%;
}
.form-wrapper .fields-sub-heading {
    font-weight: 500;
    font-size: 16px;
}
.form-wrapper .fields-heading,
.form-wrapper .fields-sub-heading {
    margin-bottom: 1rem;
}
.field {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
    position: relative;
}
/* .email-code .field {
    margin-bottom: 0;
} */
.info-message-with-icon {
    color: orange;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.info-message-with-icon.banner {
    padding: 15px;
    background-color: #ffa5001a;
    border-radius: 4px;
}
.info-message-with-icon .info-icon {
    margin-right: 10px;
}
.info-message-with-icon .info-icon i {
    font-size: 18px;
}
.info-message-with-icon .info-message {
    font-size: 13px;
}
.error-message {
    color: #fd6868;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    background: var(--error-light);
}
.email-code .error-message {
    padding: 10px;
    background-color: var(--error-light);
    border-radius: 4px;
    font-weight: 500;
}
.field.file-upload {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 6px;
    border: 2px dashed var(--text-dark-secondary);
}
.field.file-upload i {
    font-size: 22px;
}
.field.cloud {
    cursor: pointer;
}
.field.cloud .cloud-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 3px 25px 0 rgba(0,0,0,0.08);
    width: 240px;
    height: 130px;
}
.field.cloud .cloud-logo img {
    max-width: 160px;
}
.field.cloud .cloud-logo #cloud-aws-logo {
    max-width: 100px;
}
.field.cloud .cloud-logo .active-state-icon {
    position: absolute;
    top: 10px;
    left: 10px;
}
.field.cloud .cloud-logo .active-state-icon i {
    font-size: 22px;
}
.field.cloud .cloud-logo .active-state-icon:not(:last-child) i {
    margin-right: 10px;
}
.field.cloud .cloud-logo .active-state-icon:not(.default).checked i:first-child {
    color: #82ce43;
}
.field.cloud .cloud-logo .active-state-icon:not(.default):not(.checked) i:first-child {
    color: var(--text-dark-terniary);
}
.field.cloud .cloud-logo .active-state-icon.default i {
    color: #a1acb7;
}
.field.cloud .cloud-logo .active-state-icon i.warning {
    color: orange;
}
.field.cloud .cloud-name {
    text-align: center;
    padding-top: 20px;
    font-weight: 500;
    font-size: 13px;
    color: var(--text-dark-terniary);
}
.field.flex-row {
    flex-flow: row nowrap !important;
    align-items: center;
}
.field .disabled {
    background: #f7f7f7;
    cursor: not-allowed;
}
.field .color-preview {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    background-color: black;
}
.field-tooltip {
    margin-bottom: 20px;
    font-size: 12px;
    color: var(--text-dark-primary);
}
.field.flex-row label,
.field.flex-row input {
    margin: 0 !important;
}
.field.flex-row input {
    margin-right: 10px !important;
}
.field label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--text-dark-terniary);
}
.field input::-webkit-outer-spin-button,
.field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.field input[type=number] {
    -moz-appearance: textfield;
}
.field input[type=text],
.field input[type=number] {
    line-height: 30px;
    padding: 0 10px;
}
.form-wrapper .poc-trial-form-sms .field input[type=number],
.form-wrapper .poc-trial-form-sms .field input[type=text],
.form-wrapper .poc-trial-form-email .field input[type=number],
.form-wrapper .poc-trial-form-email .field input[type=text] {
    line-height: 50px;
    max-width: 50px;
    text-align: center;
    font-size: 22px;
}
.field input[type=text],
.field input[type=number],
.field textarea {
    font-family: inherit;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 400;
    border-radius: 4px;
    border: 2px solid #e1e3eb;
    transition: border .15s ease,
                background-color .15s ease,
                color .15s ease;
}
.field input[type=checkbox]:hover {
    cursor: pointer;
}
.field input[type=text]:focus,
.field input[type=number]:focus,
.field textarea:focus {
    outline: none !important;
    border-color: #0060df;
}
.field input[type=text]:disabled,
.field input[type=number]:disabled,
.field textarea:disabled {
    background: #f8f8f8 !important;
    cursor: not-allowed
}
.form-wrapper .poc-trial-form .field textarea {
    padding: 1rem 20px;
    resize: vertical;
}
.form-wrapper .poc-trial-form .field .delete-user-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background-color: #ffe8e8;
    color: rgb(236, 79, 79);
    border-radius: 4px;
    transform: translateY(78%);
    cursor: pointer;
}
.form-wrapper .poc-trial-form .field .delete-user-button.disabled {
    background-color: #ebebeb;
    color: #646464;
    cursor: not-allowed;
}
.field .flex-icon {
    display: flex;
    align-items: center;
}
.field .flex-icon i {
    margin-right: 10px;
    margin-bottom: 10px;
}
.form-wrapper .poc-trial-form .field .contact-datepicker,
.form-wrapper .poc-trial-form .field .poc-timeline-datepicker {
    position: absolute;
    top: 70px;
    z-index: 1;
}
.form-wrapper .poc-trial-form .field .contact-datepicker {
    right: 0;
}
.form-wrapper .poc-trial-form .field .poc-timeline-datepicker {
    left: 0;
}
.form-wrapper .poc-trial-form .field .color-picker {
    left: 0;
}
.form-wrapper .poc-trial-form .field .mobile-area-code {
    margin-right: 5px;
}
.multiple-fields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.multiple-fields .field:not(:last-child) {
    margin-right: 10px;
}
.field.cloud:not(:last-child) {
    margin-right: 30px;
}
.multiple-fields .field.flex-width {
    flex: 1;
}
.multiple-fields.primary-contact-auto-fill {
    margin-bottom: 20px;
}
.multiple-fields.primary-contact-auto-fill .heading {
    width: 100%;
    margin-bottom: 20px;
    color: var(--text-dark-terniary);
    font-size: 16px;
}
.form-wrapper .error-messages-wrapper {
    background-color: var(--error-light);
    color: var(--error-dark);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 40px;
}
.form-wrapper .error-messages-wrapper .error-heading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1rem;
}
.form-wrapper .error-messages-wrapper .error-heading .error-heading-icon {
    margin-right: 10px;
}
.form-wrapper .error-messages-wrapper .error-message-wrapper {
    margin-bottom: 1rem;
}
.form-wrapper .error-messages-wrapper .error-message-wrapper .error-message {
    margin-bottom: 10px;
}
.form-wrapper .poc-trial-form .form-submit-button {
    font-family: inherit;
    font-size: 16px;
    padding: 15px;
    max-width: 160px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 6px;
    background-color: var(--brand-accent-blue);
    color: #fff;
    border: none;
    outline: none;
    transition: box-shadow .2s ease;
}
.form-wrapper .poc-trial-form .form-submit-button:hover {
    box-shadow: 0 9px 30px 0 rgba(0, 123, 252, 0.3);
}
.form-wrapper .poc-trial-form .form-submit-button.disabled {
    background-color: #e1e1e1;
    color: var(--dark-default);
    box-shadow: 0 9px 23px 0 rgba(0,0,0,0.1);
    cursor: not-allowed;
}
.form-wrapper .poc-trial-form input[type=color],
.form-wrapper .poc-trial-form input[type=file] {
    cursor: pointer;
}
.confidentiality-link {
    cursor: pointer;
    text-decoration: underline;
    color: var(--dark-default);
}
.success-section .success-message-wrapper {
    padding: 200px 0;
}
.success-section .success-message-wrapper .heading {
    margin-bottom: 20px;
    font-size: 34px;
}
.success-section .success-message-wrapper .sub-heading {
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 300;
}
.success-section .success-message-wrapper .return-home-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.success-section .success-message-wrapper .return-home-button-wrapper .return-home-button {
    text-decoration: none;
    padding: 15px 20px;
    background: var(--brand-accent-blue);
    border-radius: 5px;
    color: white !important;
    display: block;
    box-shadow: 0 9px 30px 0 rgba(0,123,252,.3);
    font-weight: 500;
}
/*
    Form Validation Styling
*/
.form-input-error {
    border-color: #f87373 !important;
    color: #f87373;
}
/* V-Select Custom Styling */
.users .user-role {
    width: 200px !important;
    height: 34px !important;
}
.users .mobile-area-code,
.primary-contact .mobile-area-code {
    width: 130px !important;
}
.vs__dropdown-toggle {
    padding: 0 0 0 !important;
}
.vs__selected {
    margin: 0 0 0 !important;
}
.vs__search {
    cursor: pointer !important;
}
.vc-chrome {
    font-family: inherit;
}
.vs__actions {
    cursor: pointer !important;
}
.contact-datepicker-toggle {
    width: 220px;
    height: 34px;
    border: 2px solid #e1e3eb;
    border-radius: 4px;
}
.form-wrapper p {
    margin: 0;
    padding: 0;
}
.required {
    color: var(--error-dark);
}
@media only screen and (max-width: 600px) {
    .poc-trial-form-section .form-wrapper {
        padding: 60px 20px;
    }
    .form-wrapper .fields-heading {
        font-size: 16px;
    }
}
@media only screen and (min-width: 600px) {
    .poc-trial-form-section .form-wrapper {
        transform: translateY(-100px);
    }
}
@media only screen and (max-width: 768px) {
    #primaryContactMobile {
        width: 100%;
    }
    .field {
        margin-right: 0 !important;
    }
    .multiple-fields.primary-contact-auto-fill {
        background: #f7f7f7;
        padding: 10px;
        border-radius: 4px;
    }
    .field.delete-user-locked {
        display: none;
    }
    .form-wrapper .poc-trial-form .field .delete-user-button {
        transform: translateY(0);
    }
    .multiple-fields.clouds {
        align-content: center;
    }
    .multiple-fields .field.cloud {
        margin-right: 0 !important;
    }
}
@media only screen and (min-width: 768px) {
    .multiple-fields {
        flex-direction: row;
    }
    #company-name-field,
    #zipcode-field {
        max-width: 165px;
    }
}
@media only screen and (min-width: 1024px) {
    .form-wrapper form {
        padding: 50px;
    }
}
</style>